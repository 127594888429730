import React from "react";
import "./App.css";

import { auth } from "./firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { SignIn } from "./components/signIn";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavbarMy from "./components/navbar";
import FeaturedMusicList from "./components/featuredMusicList";
import Music from "./Music";

function App() {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <SignIn />;
  }

  return (
    <Router>
      <div className="App vh-100">
        <header>
          <NavbarMy user={user} />
        </header>
        <main className="d-flex">
          <AuthorizedRoutes user={user} />
        </main>
      </div>
    </Router>
  );
}

const AuthorizedRoutes = ({ user }: { user: firebase.User }) => {
  return (
    <Switch>
      <Route path="/" exact>
        <Home user={user} />
      </Route>
      <Route path="/music">
        <Music user={user} />
      </Route>
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
};

const Home = ({ user }: { user: firebase.User }) => {
  return (
    <div className="container">
      <FeaturedMusicList />
    </div>
  );
};

const NoMatch = () => {
  return (
    <div>
      <div>404</div>
    </div>
  );
};

export default App;
