import React from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore } from "../firebase/firebase";
import SheetMusic from "../interfaces/sheetmusic";

const FeaturedMusicList = () => {
  const musicRef = firestore.collection("music");

  const [music, loading, error] = useCollectionData<SheetMusic>(musicRef);

  if (error) {
    console.error(error);
    return null;
  }

  if (loading || !music) {
    return null;
  }

  return (
    <Container>
      <Row className="my-5">
        <Col className="text-center">
          <h1>Aktuālās notis</h1>
          <ListGroup>
            {music.map((sheet) => {
              if (!sheet.featured) {
                return null;
              }

              return (
                <ListGroup.Item
                  action
                  key={sheet.id}
                  as="a"
                  href={sheet.downloadURL}
                  download
                >
                  {sheet.title} - {sheet.author}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default FeaturedMusicList;
