import React from "react";
import { Link } from "react-router-dom";
import { auth } from "../firebase/firebase";
import { Navbar, Nav, Form, FormControl, Button } from "react-bootstrap";

const NavbarMy = ({ user }: { user: firebase.User }) => {
  return (
    <>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand as={Link} to="/">
          Koris OGLE : Notis
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/music">
              Music
            </Nav.Link>
          </Nav>
          {/* <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-success">Search</Button>
          </Form> */}
          <Button
            variant="outline-success"
            onClick={() => auth.signOut()}
            className="ml-1"
          >
            Iziet
          </Button>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavbarMy;
