import React, { useState } from "react";
import { Container, Row, Col, Button, Form, ListGroup } from "react-bootstrap";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import UploadForm from "./components/uploadForm";
import Roles from "./constants/roles";
import { firestore } from "./firebase/firebase";
import DbUser from "./interfaces/dbuser";
import SheetMusic from "./interfaces/sheetmusic";

const Music = ({ user }: { user: firebase.User }) => {
  const userRef = firestore.doc(`users/${user?.uid}`);
  const [dbUser, loadignUserDoc] = useDocumentData<DbUser>(userRef);

  const musicRef = firestore.collection("music");
  const [music, loadingMusic] = useCollectionData<SheetMusic>(
    musicRef.orderBy("title")
  );

  const [upload, setupload] = useState(false);
  const [filter, setfilter] = useState("");

  if (loadingMusic || loadignUserDoc) {
    return <div>Loading...</div>;
  }

  const isAdmin = dbUser?.roles.includes(Roles.admin);

  const onFeatured = (sheet: SheetMusic) => {
    console.log(sheet);

    let ref = musicRef.doc(sheet.id);
    ref.update({
      featured: !sheet.featured,
    });
  };

  if (!music) {
    return null;
  }

  return (
    <>
      <Container>
        <Row>
          <Col className="text-center my-3">
            <h2>Notis</h2>
          </Col>
        </Row>

        {isAdmin ? (
          <Row>
            <Col className="text-center my-3">
              <Button onClick={() => setupload((prev) => !prev)}>
                Augšuplādēt notis
              </Button>
            </Col>
          </Row>
        ) : null}

        {upload ? (
          <Row>
            <Col className="text-center my-3">
              <UploadForm />
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col className="text-center my-3">
            <Form>
              <Form.Group controlId="uploadFormAuthor">
                <Form.Label>Filtrēt notis</Form.Label>
                <Form.Control
                  type="text"
                  name="filter"
                  onChange={(e: any) => setfilter(e.target.value.toLowerCase())}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col>
            <ListGroup>
              {music.map((sheet) => {
                if (filter) {
                  let toFilter = (sheet.title + sheet.author).toLowerCase();
                  if (!toFilter.includes(filter)) return null;
                }

                if (isAdmin) {
                  return (
                    <ListGroup.Item
                      // action
                      key={sheet.id}
                      // as="a"
                      // href={sheet.downloadURL}
                      // download
                      className="group-item"
                    >
                      {sheet.title} - {sheet.author}
                      <div>
                        <Button
                          as="a"
                          href={sheet.downloadURL}
                          download
                          className="mx-3"
                        >
                          Download
                        </Button>
                        {isAdmin ? (
                          <Button onClick={() => onFeatured(sheet)}>
                            {sheet.featured ? "-" : "+"}
                          </Button>
                        ) : null}
                      </div>
                    </ListGroup.Item>
                  );
                }

                return (
                  <ListGroup.Item
                    action
                    key={sheet.id}
                    as="a"
                    href={sheet.downloadURL}
                    download
                    className="group-item"
                  >
                    {sheet.title} - {sheet.author}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Music;
