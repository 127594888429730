import React, { useState } from "react";
import { auth } from "../firebase/firebase";

export const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<Error | null>(null);

  const router = {
    push: (path: string) => {
      return path;
    },
  };

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setError(null);
    console.log("submitting form");

    auth
      .signInWithEmailAndPassword(email, password)
      .then((_) => {
        setEmail("");
        setPassword("");
        setError(null);
        router.push("/");
      })
      .catch((error: Error) => {
        setError(error);
      });
  };

  const isInvalid = password === "" || email === "";

  return (
    <div className="container vh-100">
      {error ? <div>{error.message}</div> : null}
      <div className="container d-flex justify-content-center align-items-center h-100">
        <form onSubmit={submit}>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Email address</label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e: any) => setEmail(e.target.value)}
            />
            <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">Password</label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              onChange={(e: any) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isInvalid}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
